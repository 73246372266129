<template>
  <div class="Skills">
    <div class="container">
        <h3 class="Header" >High End Visuals and Animation</h3>
        <b-row>
            <b-col cols lg='4' sm='12' class="skillsBlock" v-for="(skills, index) in acf.acf.skills_block" :key="index">
              <b-link class="skillsNoUnderline" :to='skills.find_out_more_link'>
                <div class="outerSkills_image">
                  <b-img class="Skills_image" :src="skills.skills_main_image"></b-img>                  
                </div>
                <div class="innerSkills_block">
                    <h3 class="Skill_title">{{skills.skill_title}}</h3>
                    <div class="SkillExerpt">
                        <p class="Skill_excerpt" v-html="skills.skills_excerpt"></p>
                    </div>
                    <b-button :to='skills.find_out_more_link' class="find_out_more" variant="danger">Find Out More</b-button>
                </div>
              </b-link>
            </b-col>
        </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'Skills',
  data: function() {
    return {
      acf: [],
    };
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-studios/wp-json/wp/v2/skillssection/"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data[0]
        )); //API data wanted pulled out
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
::v-deep .btn-danger {
    color: #fff;
    background-color: #efd170;
    border-color: #efd170!important;
}
.outerSkills_image {
    display: block;
    overflow: hidden;
}
a.skillsNoUnderline {
    text-decoration: none!important;
}
.SkillExerpt {
    margin-bottom: 35px;
    display: flex;
}
.Skills_image {
    width: 100%;
    transition: all .5s;
}
.Skills_image:hover, .Skills_image:focus  {
    width: 100%;
    transform: scale(1.2);
}
h3.Skill_title {
    color: #7ebd63;
    font-weight: 100;
    font-size: 20px;
    margin-bottom: 20px;
}
h3.Skill_title:hover {
    text-decoration: underline;
    font-weight: 100;
}
p.Skill_excerpt {
    color: #fff;
    font-size: 14px;
}
.skillsBlock {
    text-align: left;
    margin-bottom: 40px;
}
a.btn.find_out_more.btn-danger {
    background-color: #efd170;
    border-color: #efd170!important;
    border-radius: 0px;
    width: 163px;
    height: 31px;
    line-height: 1.1;
    font-size: 15px;
    color: #010322;
    position: absolute;
    bottom: 0;
}
a.btn.find_out_more.btn-danger:hover {
    background-color: #7ebd63;
    border-color: #7ebd63!important;
}
.Skills {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 9em;
}
h3.Header {
    color: #7ebd63;
    text-transform: uppercase;
    text-align: left;
    font-size: 30px;
    padding-bottom: 15px;
    font-weight: 100;
    margin-bottom: 40px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
