import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    props: true
  },
  {
    path: "/photo-real-visualisation-and-rendering",
    name: "photo-real-visualisation-and-rendering",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/independent-skills.vue"),
    props: true
  },
  {
    path: "/3d-walking",
    name: "3d-walking",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/independent-skills.vue"),
    props: true
  },
  {
    path: "/models",
    name: "models",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/independent-skills.vue"),
    props: true
  },
  {
    path: "/water-colours",
    name: "water-colours",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/independent-skills.vue"),
    props: true
  },
  {
    path: "/virtual-reality",
    name: "virtual-reality",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/independent-skills.vue"),
    props: true
  },
  {
    path: "/3d-floor-plans",
    name: "3d-floor-plans",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/independent-skills.vue"),
    props: true
  },
  {
    path: "/website-design-and-development",
    name: "website-design-and-development",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/independent-skills.vue"),
    props: true
  },
  {
    path: "/ecommerce-and-payment-solutions",
    name: "ecommerce-and-payment-solutions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/independent-skills.vue"),
    props: true
  },
  {
    path: "/mobile-apps",
    name: "mobile-apps",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/independent-skills.vue"),
    props: true
  },
  {
    path: "/portfolio",
    name: "portfolio",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/portfolio.vue"),
    props: true
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/blogGroup.vue"),
    props: true
  },
  {
    path: "/blog/:id",
    name: "blog",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/blog.vue"),
    props: true
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
