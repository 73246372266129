<template>
  <div class="Home">
    <div class="pageWrap">
      <div class="video-banner">
        <div class="vimeo-wrapper wow fadeIn" data-wow-duration="1.2s" style="visibility: visible; animation-duration: 1.2s; animation-name: fadeIn;">
          <iframe src="https://player.vimeo.com/video/641979205?muted=1&amp;loop=1&amp;autoplay=1&amp;controls=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div> 
      </div>
      <!-- <div class="outterBlock">
        <div class="Home_text_block container">
          <h2 class="lineOne">{{acf.acf.header_1}}</h2>
          <h2 class="lineTwo">{{acf.acf.header_2}}</h2>
          <h2 class="lineThree">{{acf.acf.header_3}}</h2>
        </div>
      </div> -->
    </div>
    <div class="componentHome_block">
      <div id="skills" class="skillsBlock">
        <Skills />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="blog" class="skillsBlock">
        <Blogs />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import About from '@/components/About.vue'
import Contact from '@/components/Contact.vue'
import Skills from '@/components/Skills.vue'
import Blogs from '@/components/Blog.vue'
export default {
  name: 'Home',
  components: {
    About,
    Contact,
    Skills,
    Blogs,
  },
  data: function() {
    return {
      acf: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-studios/wp-json/wp/v2/pages/6"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.skillsBlock {
  margin-top: -10em;
}
::v-deep .btn-danger {
  color: #fff;
  background-color: #7ebd63;
  border-color: #7ebd63!important;
}
.video-banner {
  width: 100%;
  background-size: 100% auto!important;
  background-repeat: no-repeat!important;
  height: 37vw;
  max-height: 800px;
  background-position: 50% 50%!important;
  overflow: hidden;
}
.vimeo-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}
.vimeo-wrapper iframe {
  top: 25%;
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-title-wrap {
  display: flex;
  justify-content: center;
  position: absolute;
  height: 56.25%;
  width: 100%;
}
.video-overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}
.vimeo-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
}
.HomeTopContent {
  position: absolute;
  top: 40%;
}
#myVideo {
  /* position: fixed; */
  right: 0;
  bottom: 0;
  /* min-width: 100%; */
  width: 100%;
  min-height: 100%;
}
.componentHome_block {
  display: block;
  /* margin-top: 100px; */
}
.outerImageComp {
  text-align: right;
  margin-top: -200px;
}
.computerImage {
  /* width: 100%; */
}
#skills {
  scroll-behavior: smooth;
  background: #010322;
}
.Home_text_block {
  text-align: left;
  /* margin-left: -12px; */
  /* opacity: 0.75; */
  /* margin-top: 170px; */
  /* margin-top: -96px; */
  z-index: 9999;
  position: relative;
  top: -28em;
}
.lineOne {
  font-size: 49px;
  font-weight: 400;
  color: #efd170;
  text-transform: uppercase;
  background: #000322;
  display: inline;
  padding: 10px;
  position: relative;
}
.lineTwo {
  font-size: 49px;
  font-weight: 400;
  color: #fff; 
  text-transform: uppercase;
  background: #000322;
  margin-bottom: 0px;
  padding: 10px;
  position: relative;  /* display: inline; */
  width: 321px;
}
.lineThree {
  font-size: 49px;
  font-weight: 400;
  color: #7ebd63;
  text-transform: uppercase;
  background: #000322;
  display: inline;
  padding: 10px;
  position: relative;
}
.TopHomeback-Image {
  /* background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/backgroundHome.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* height: 100vh; */
  height: 35vh;
  /* background-color: #000322; */
  padding-bottom: 50px;
  padding-top: 50px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
/* @media only screen and (max-width: 1650px) and (min-width :1352px) {
  .TopHomeback-Image {
    background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/HeaderImage_OCS_Studios.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 87vh;
  }
}

@media only screen and (max-width: 1352px) {
  .TopHomeback-Image {
    background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/HeaderImage_OCS_Studios.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 75vh;
  }
} */

@media only screen and (max-width: 1400px) {
  .Home_text_block {
    text-align: left;
    z-index: 9999;
    position: relative;
    top: -19em;
  }
}
@media only screen and (max-width: 1200px) {
  .outerImageComp {
    display: none;
  }
  .componentHome_block {
    display: block;
    margin-top: 0px;
  }
}
/* @media only screen and (max-width: 1030px) {

} */
@media only screen and (max-width: 1028px) {
  .lineOne {
    font-size: 30px;
  }
  .lineTwo {
    font-size: 30px;
    width: 200px;
  }
  .lineThree {
    font-size: 30px;
  }
  .Home_text_block {
    text-align: left;
    margin-top: 80px;
  }
}

@media only screen and (max-width: 500px) {
  .video-banner {
    width: 100%;
    background-size: 100% auto!important;
    background-repeat: no-repeat!important;
    height: 100vw;
    max-height: 800px;
    background-position: 50% 50%!important;
    overflow: hidden;
  }
  .vimeo-wrapper {
    position: relative;
    padding-bottom: 100%;
    padding-top: 0;
    height: 0;
  }
  .Home_text_block {
    text-align: left;
    margin-left: 0px;
  }
  #myVideo {
    display: none;
  }
  .TopHomeback-Image {
    position: absolute;
    top: 110px;
    /* background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/backgroundHome.png'); */
    /* background-repeat: no-repeat;
    background-size: 100% 100%; */
    /* height: 100vh; */
    /* height: 70vh; */
    /* background-color: #000322; */
    /* padding-bottom: 50px;
    padding-top: 50px; */
  }
  .outerImageComp {
    display: block;
    margin-top: 0px!important;
    text-align: start!important;
  }
  .computerImage {
    width: 100%;
  }
}
@media only screen and (max-width: 375px) {
  .lineOne {
    font-size: 25px;
  }
  .lineTwo {
    font-size: 25px;
    width: 340px;
  }
  .lineThree {
    font-size: 25px;
  }
}
</style>
