<template>
  <div class="About">
    <div class="container">
        <b-row class="extraPad">
            <b-col cols lg='6' sm='12'>
                <h3 class="Header">About Us</h3>
                <div class="LeftSection">
                    <h3 class="mainTextAbout">{{acf.acf.main_text}}</h3>
                    <p class="aboutDescription" v-html="acf.acf.about_description"></p>
                </div>
            </b-col>
            <b-col cols lg='6' sm='12'>
                <h3 class="Header">We Offer</h3>
                <div class="RightSection">
                    <ul class="weOffer_block" v-for="(offer, index) in acf.acf.we_offer_text" :key="index">
                        <li class="weOffer_text">{{offer.text}}</li>
                    </ul>
                </div>
            </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'About',
  data: function() {
    return {
      acf: [],
    };
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-studios/wp-json/wp/v2/aboutsection/"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data[0]
        )); //API data wanted pulled out
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
::v-deep .btn-danger {
    color: #fff;
    background-color: #7ebd63;
    border-color: #7ebd63!important;
}
.extraPad {
    padding-bottom: 30px;
}
.RightSection {
    margin-left: 3px;
    margin: 40px 0 0;
}
ul.weOffer_block {
    color: #000322;
    text-align: left;
    list-style-type: disc;
    font-size: 14px;
    margin-bottom: 0.5em;
}
li.weOffer_text {
    display: list-item!important;
}
.LeftSection {
    width: 400px;
}
h3.mainTextAbout {
    color: #7ebd63;
    font-size: 20px;
    font-weight: 100;
    text-align: left;
    padding-bottom: 30px;
}
p.aboutDescription {
    color: #000322;
    font-size: 14px;
    text-align: left;
}
h3.Header {
    color: #7ebd63;
    text-transform: uppercase;
    text-align: left;
    font-size: 30px;
    padding-bottom: 15px;
    font-weight: 100;
}
.About {
    background-color: #F5F5F5;
    padding-top: 50px;
    padding-bottom: 50px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
@media only screen and (max-width: 500px) {
  .LeftSection {
    width: 100%!important;
  }
}
</style>
