<template>
  <div id="Home">
    <Home />
  </div>
</template>

<script>
import Home from '@/components/Home.vue'

export default {
  name: 'App',
  components: {
    Home,
  }
}
</script>
<style scoped>
::v-deep .btn-danger {
  color: #fff;
  background-color: #7ebd63;
  border-color: #7ebd63!important;
}
</style>

